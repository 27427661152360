import React, { ReactElement } from "react";

const TermsOfService = (): ReactElement => {
    return (
        <div className="bg-darkGray min-h-screen p-4 sm:p-8 text-offWhite space-y-4">
            <h1 className="mx-auto text-3xl text-center font-changa">
                Terms of Service
            </h1>
            <p>Last Updated: February 4, 2021.</p>
            <p>
                Welcome to the Streamlux website (www.streamlux.com) (the{" "}
                <strong>“Site”</strong>). Please read these Terms of Service
                (the <strong>“Terms”</strong>) carefully because they govern
                your use of our website (<strong>“Site”</strong>) and desktop
                application (<strong>“App”</strong>), application program
                interface(s) (“API(s)”) and other products and services we may
                later own or operate (collectively called the “Services”).
            </p>
            <p>
                <strong>1. Agreement to these Terms</strong>
            </p>
            <p>
                By using our Services, you agree to be bound by these Terms. If
                you don’t agree to these Terms, do not use the Services.{" "}
            </p>
            <p>
                <strong>2. Changes to the Terms or Services</strong>
            </p>
            <p>
                We may modify the Terms at any time, in our sole discretion. If
                we do so, we’ll let you know either by posting the modified
                Terms on the Site or through other communications. If you
                continue to use the Services, you are indicating that you agree
                to the modified Terms. We may change or discontinue all or any
                part of the Services, at any time and without notice, at our
                sole discretion.
            </p>
            <p>
                ARBITRATION NOTICE: IF YOU'RE A U.S. RESIDENT, YOU ALSO AGREE TO
                THE FOLLOWING MANDATORY ARBITRATION PROVISIONS:
            </p>
            <p>
                We Both Agree To Arbitrate. You and Streamlux agree to resolve
                any claims relating to these Terms or the Services through final
                and binding arbitration.
            </p>
            <p>
                <strong>3. Who May Use the Services</strong>
            </p>
            <p>
                You may use the Services only if you are 13 years or older and
                are not barred from using the Services under applicable law.
            </p>
            <p>
                In order to use certain features of the Services, you must
                create an account (“Account”) via the Site and become a
                registered user (“User”). As a User, you may use certain
                features of the Services as described in Section 4. You have to
                provide us with accurate and complete information and keep it
                up-to-date. You’ll notify us immediately of any unauthorized use
                of your Account and you’re responsible for all activities that
                occur under your Account, whether or not you know about them.
            </p>
            <p>
                <strong>6. Subscriptions and Payment</strong>
            </p>
            <p>
                <strong>6.1 Pricing</strong>
            </p>
            <p>
                Subject to these Terms, the Services are provided to you for
                free up to certain limits and with limited features. Usage over
                these limits or access to certain features requires you to
                purchase a subscription (“Subscription”). Your rights and
                obligations will be based on the type of Subscription you choose
                (i.e., free, basic, or premium Subscription). Details about the
                different types of Subscriptions, and the limits and available
                features associated with the Services, are available at
                www.streamlux.com/pricing
            </p>
            <p>
                You may change the type of Subscription at any time by (i)
                emailing us at support@streamlux.com and following any
                instructions, if any, we provide to you in response to your
                change request or (ii) initiating a change through your Account
                settings within the Services.
            </p>
            <p>
                <strong>6.2 Payment Terms</strong>
            </p>
            <p>
                When you purchase a Subscription (such purchase, a
                “Transaction”) you expressly authorize us (or our third party
                payment processor) to charge you for such Transaction. We may
                ask you to supply additional information relevant to your
                Transaction, including, without limitation, your credit-card
                number, the expiration date of your credit card, and your
                address(es) for billing (such information,{" "}
                <strong>“Payment Information”</strong>). You represent and
                warrant that you have the legal right to use all payment
                method(s) represented by any such Payment Information. When you
                initiate a Transaction, you authorize us to provide your Payment
                Information to our third party service providers so we can
                complete your Transaction and to charge your payment method for
                the type of Transaction you have selected (plus any applicable
                taxes and other charges). You may need to provide additional
                information to verify your identity before completing your
                Transaction (such information is included within the definition
                of Payment Information).
            </p>
            <p>
                <strong>6.3 Authorization for Recurring Payments</strong>
            </p>
            <p>
                If you purchase a Subscription, you will be charged the
                then-applicable Subscription fee (“Subscription Fee”) at the
                beginning of your Subscription and each month, quarter or year
                thereafter, depending on the term of your Subscription, at the
                then-current rate. Subscription Fees are outlined at
                www.streamlux.com/pricing. Please note that our Subscription
                Fees are subject to change, although we will notify you before
                we effect any change in Subscription Fees.
            </p>
            <p>
                By agreeing to these Terms and purchasing a Subscription, you
                acknowledge that your Subscription has recurring payment
                features and you accept responsibility for all recurring payment
                obligations prior to cancellation of your Subscription by you or
                Streamlux. We (or our third party payment processor) will
                automatically charge you in accordance with the terms of your
                Subscription (e.g., each month, quarter or year), on the
                calendar day corresponding to the commencement of your
                Subscription, using the Payment Information you have provided.
                In the event your Subscription begins on a day not contained in
                a given month, your payment method will be charged on a day in
                the applicable month or such other day as we deem appropriate.
                For example, if you started a monthly Subscription on January
                31st, your next payment date is likely to be February 28th, and
                your payment method would be billed on that date. We may also
                periodically authorize your payment method in anticipation of
                applicable fees or related charges. Your Subscription continues
                until cancelled by you or we terminate your access to or use of
                the Services or the Subscription in accordance with these Terms.
            </p>
            <p>
                <strong>6.4 Cancelling Subscriptions</strong>
            </p>
            <p>
                You may cancel your Subscription at any time but please note
                that such cancellation will be effective at the end of the
                then-current Subscription period. YOU WILL NOT RECEIVE A REFUND
                OF ANY PORTION OF THE SUBSCRIPTION FEE PAID FOR THE THEN-CURRENT
                SUBSCRIPTION PERIOD AT THE TIME OF CANCELLATION. To cancel, you
                can either (i) email us at support@streamlux.com and follow any
                instructions, if any, we provide to you in response to your
                cancellation request, or (ii) initiate a cancellation through
                your Account settings within the Services. You will be
                responsible for all Subscription Fees (plus any applicable taxes
                and other charges) incurred for the then-current Subscription
                period. If you cancel, we will allow you to utilize features
                associated with such Subscription until the most recently
                paid-up Subscription period ends. Cancelling your Subscription
                won’t cancel your Account. See Section 14 (Termination) below
                for information on terminating your Account.
            </p>
            <p>
                At the end of the Subscription period, or if you stop paying the
                Subscription Fee, you will have to select which devices you
                would like to keep saved, depending on your new subscription.
                Your animations will not be removed or changed in any way. All
                other data will remain unchanged.
            </p>
            <p>
                <strong>7. Content and Content Rights</strong>
            </p>
            <p>
                For purposes of these Terms: (i) “Content” means text, graphics,
                designs, images, software, audio, video, works of authorship of
                any kind, and information or other materials that are posted,
                generated, provided or otherwise made available through the
                Services; and (ii) “User Content” means any Content that Users
                create through the use of the App (i.e. light effects).
            </p>
            <p>
                <strong>
                    7.1 Ownership and Responsibility of User Content
                </strong>
            </p>
            <p>
                As between you and Streamlux, you have partial ownership of your
                User Content. As such, under your discretion, any decisions to
                market your user content must be done through the Services. A
                portion of the profits from any User Content will be made
                available to Streamlux. Subject to the foregoing, Streamlux and
                its licensors exclusively own all right, title and interest in
                and to the Services and Content, including all associated
                intellectual property rights. You agree not to remove, alter or
                obscure any copyright, trademark, service mark or other
                proprietary rights notices incorporated in or accompanying the
                Services or Content.
            </p>
            <p>
                <strong>7.2 Data Maintenance and Backup Procedures</strong>
            </p>
            <p>
                In the event of any loss or corruption of any data associated
                with the Services, Streamlux will use commercially reasonable
                efforts to restore the lost or corrupted data from the latest
                backup of such data maintained by Streamlux. EXCEPT FOR THE
                FOREGOING, STREAMLUX WILL NOT BE RESPONSIBLE FOR ANY LOSS,
                DESTRUCTION, ALTERATION, UNAUTHORIZED DISCLOSURE OR CORRUPTION
                OF ANY SUCH DATA.
            </p>
            <p>
                <strong>8. Feedback</strong>
            </p>
            <p>
                We welcome feedback, comments and suggestions for improvements
                to the Services (“Feedback”) by emailing us at{" "}
                <u>support@streamlux.com</u> or posting on our Discord server.
                As we need to be able to freely work with your Feedback to
                improve the Services, you hereby irrevocably transfer and assign
                all right, title and interest (including all intellectual
                property rights, such as copyrights or trade secrets) in and to
                the Feedback, including any and all “moral rights” that you
                might have in such Feedback, and you hereby forever waive and
                agree never to assert any and all “moral rights” you may have in
                the Feedback.
            </p>
            <p>
                <strong>9. Privacy Policy</strong>
            </p>
            <p>
                Please refer to our Privacy Policy (www.streamlux.com/privacy)
                for information on how we collect, use and disclose information
                from our users.
            </p>
            <p>
                <strong>10. General Prohibitions</strong>
            </p>
            <p>You agree not to do any of the following:</p>
            <ul>
                <li>
                    Post, upload, publish, submit or transmit any User Content
                    that: (i) infringes, misappropriates or violates a third
                    party’s patent, copyright, trademark, trade secret, moral
                    rights or other intellectual property rights, or rights of
                    publicity or privacy; (ii) violates, or encourages any
                    conduct that would violate, any applicable law or regulation
                    or would give rise to civil liability; (iii) is fraudulent,
                    false, misleading or deceptive; (iv) is defamatory, obscene,
                    pornographic, vulgar or offensive; (v) promotes
                    discrimination, bigotry, racism, hatred, harassment or harm
                    against any individual or group; (vi) is violent or
                    threatening or promotes violence or actions that are
                    threatening to any person or entity; or (vii) promotes
                    illegal or harmful activities or substances.
                </li>
            </ul>
            <li>
                Use, display, mirror or frame the Services, or any individual
                element within the Services, Streamlux’s name, any Streamlux
                trademark, logo or other proprietary information, or the layout
                and design of any page or form contained on a page, without
                Streamlux’s express written consent;
            </li>
            <li>
                Access, tamper with, or use non-public areas of the Services,
                Streamlux’s computer systems, or the technical delivery systems
                of Streamlux’s providers;
            </li>
            <li>
                Attempt to probe, scan or test the vulnerability of any
                Streamlux system or network or breach any security or
                authentication measures;
            </li>
            <li>
                Avoid, bypass, remove, deactivate, impair, descramble or
                otherwise circumvent any technological measure implemented by
                Streamlux or any of Streamlux’s providers or any other third
                party (including another user) to protect the Services or
                Content;
            </li>
            <li>
                Attempt to access or search the Services or Content or download
                Content from the Services through the use of any engine,
                software, tool, agent, device or mechanism (including spiders,
                robots, crawlers, data mining tools or the like) other than the
                software and/or search agents provided by Streamlux or other
                generally available third party web browsers;
            </li>
            <li>
                Send any unsolicited or unauthorized advertising, promotional
                materials, email, junk mail, spam, chain letters or other form
                of solicitation;
            </li>
            <li>
                Use any meta tags or other hidden text or metadata utilizing a
                Streamlux trademark, logo URL or product name without
                Streamlux’s express written consent;
            </li>
            <li>
                Use the Services or Content for any commercial purpose or for
                the benefit of any third party or in any manner not permitted by
                these Terms or the functionality of the Services;
            </li>
            <li>
                Forge any TCP/IP packet header or any part of the header
                information in any email or newsgroup posting, or in any way use
                the Services or Content to send altered, deceptive or false
                source-identifying information;
            </li>
            <li>
                Attempt to decipher, decompile, disassemble or reverse engineer
                or in any way attempt to derive the source code of any of the
                software used to provide the Services or Content;
            </li>
            <li>
                Interfere with, or attempt to interfere with, the access of any
                user, host or network, including, without limitation, sending a
                virus, overloading, flooding, spamming, or mail-bombing the
                Services;
            </li>
            <li>
                Collect or store any personally identifiable information from
                the Services from other users of the Services without their
                express permission;
            </li>
            <li>
                Impersonate or misrepresent your affiliation with any person or
                entity;
            </li>
            <li>Violate any applicable law or regulation; or</li>
            <li>
                Encourage or enable any other individual to do any of the
                foregoing.
            </li>
            <p>
                Although we’re not obligated to monitor access to or use of the
                Services or Content or to review or edit any Content, we have
                the right to do so for the purpose of operating the Services, to
                ensure compliance with these Terms, or to comply with applicable
                law or other legal requirements. We reserve the right, but are
                not obligated, to remove or disable access to any Content,
                including User Content, at any time and without notice,
                including, but not limited to, if we, at our sole discretion,
                consider any Content or User Content to be objectionable, in
                violation of these Terms or the law. We may also consult and
                cooperate with law enforcement authorities to prosecute users
                who violate the law.
            </p>
            <p>
                <strong>11. Copyright Policy</strong>
            </p>
            <p>
                Streamlux respects copyright law and expects its users to do the
                same. It is Streamlux’s policy to terminate in appropriate
                circumstances Users who repeatedly infringe the rights of
                copyright holders.{" "}
            </p>
            <p>
                <strong>
                    12. Links to and Integration with Third Party Websites or
                    Resources
                </strong>
            </p>
            <p>
                The Services may contain links to third-party websites or
                resources and may offer integration with such third-party
                websites or services (For example, we may offer integration with
                certain Google services). We provide these links and integration
                functions only as a convenience and are not responsible for the
                content, products or services on or available from those
                websites or resources or links displayed on such sites. You
                acknowledge sole responsibility for, and assume all risk arising
                from, your use of or integration with any third-party websites
                or resources.
            </p>
            <p>
                <strong>13. Publicity</strong>
            </p>
            <p>
                You agree that Streamlux may identify you and use your logo and
                trademarks (collectively, the “Marks”) on the Site and in
                marketing materials to identify you as a user of the Services,
                and you hereby grant us a non-exclusive, royalty-free license to
                do so on our Site or in any media now or later developed in
                connection with any marketing, promotion or advertising of
                Streamlux or the Services. If you do not want to allow us the
                right to use your Marks or identify you or your company you may
                opt out such marketing requests by emailing us at
                media@streamlux.com.
            </p>
            <p>
                <strong>14. Termination</strong>
            </p>
            <p>
                We may terminate your access to and use of the Services, at our
                sole discretion, at any time and without notice or liability to
                you, provided that, if you have paid for a Subscription and the
                termination is not due to your breach of this Agreement,
                Streamlux will refund you any prepaid fees for the period of
                your Subscription that extends beyond the effective date of such
                termination. Upon any termination, discontinuation or
                cancellation of Services or your Account, the following
                provisions of these Terms will survive: Feedback; Privacy
                Policy; provisions related to permissions to access Content (to
                the extent applicable); Content and Content Rights; Content
                Ownership, Responsibility and Removal; General Prohibitions;
                Warranty Disclaimers; Indemnity; Limitation of Liability;
                Governing Law and Dispute Resolution; General Terms.
            </p>
            <p>
                <strong>15. Warranty Disclaimers</strong>
            </p>
            <p>
                AS BETWEEN YOU AND STREAMLUX, THE SERVICES, CONTENT, AND
                APPLICATIONS MADE AVAILABLE ON THE SERVICES ARE PROVIDED “AS
                IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE
                FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET
                ENJOYMENT AND NON-INFRINGEMENT AND ANY WARRANTIES ARISING OUT OF
                COURSE OF DEALING OR USAGE OF TRADE. We make no warranty that
                the Services will meet your requirements or be available on an
                uninterrupted, secure, or error-free basis. We make no warranty
                regarding the quality, accuracy, timeliness, truthfulness,
                completeness or reliability of any Content. If you install and
                use an Application that is made available on the Services, we
                make no warranty that the Application will perform its intended
                function or deliver any expected results, and you acknowledge
                that you assume all risk arising from use of the Applications.
            </p>
            <p>
                <strong>16. Indemnity</strong>
            </p>
            <p>
                You will indemnify and hold harmless Streamlux and its officers,
                directors, employees and agents, from and against any claims,
                disputes, demands, liabilities, damages, losses, and costs and
                expenses, including, without limitation, reasonable legal and
                accounting fees, arising out of or in any way connected with (i)
                your access to or use of the Services or Content; (ii) your User
                Content; or (iii) your violation of these Terms.
            </p>
            <p>
                <strong>17. Limitation of Liability</strong>
            </p>
            <p>
                NEITHER STREAMLUX NOR ANY OTHER PARTY INVOLVED IN CREATING,
                PRODUCING, OR DELIVERING THE SERVICES, TOOLS, OR CONTENT WILL BE
                LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL
                DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR GOODWILL,
                SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE
                COST OF SUBSTITUTE SERVICES ARISING OUT OF OR IN CONNECTION WITH
                THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES,
                TOOLS, OR CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT
                (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL
                THEORY, AND WHETHER OR NOT STREAMLUX HAS BEEN INFORMED OF THE
                POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH
                HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME
                JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE
                LIMITATION MAY NOT APPLY TO YOU.
            </p>
            <p>
                <strong>18. Governing Law</strong>
            </p>
            <p>
                These Terms and any action related thereto will be governed by
                the laws of the State of Washington without regard to its
                conflict of laws provisions.
            </p>
            <p>
                <strong>19. Dispute Resolution for Individual Consumers</strong>
            </p>
            <p>
                Our goal is to provide you with great service, so we’ll try our
                best to resolve any disagreements that you have with us. If we
                can’t, then you and we both agree to resolve disputes related to
                your use of the Services or these Terms (each, a “Claim”) in
                binding arbitration instead of court, except that a Claim may be
                brought in small claims court if it qualifies for it. You and we
                also agree that either party may bring suit in court to enjoin
                the infringement or other misuse of intellectual property
                rights.
            </p>
            <p>
                <strong>20. General Terms</strong>
            </p>
            <p>
                These Terms constitute the entire and exclusive understanding
                and agreement between Streamlux and you regarding the Services
                and Content, and these Terms supersede and replace any and all
                prior oral or written understandings or agreements between
                Streamlux and you regarding the Services and Content. If for any
                reason an arbitrator or court of competent jurisdiction finds
                any provision of these Terms invalid or unenforceable, that
                provision will be enforced to the maximum extent permissible and
                the other provisions of these Terms will remain in full force
                and effect.
            </p>
            <p>
                You may not assign or transfer these Terms, by operation of law
                or otherwise, without Streamlux’s prior written consent. Any
                attempt by you to assign or transfer these Terms, without such
                consent, will be null and of no effect. Streamlux may freely
                assign or transfer these Terms without restriction. Subject to
                the foregoing, these Terms will bind and inure to the benefit of
                the parties, their successors and permitted assigns.
            </p>
            <p>
                Any notices or other communications provided by Streamlux under
                these Terms, including those regarding modifications to these
                Terms, will be given by Streamlux: (i) via e-mail; or (ii) by
                posting to the Site. For notices made by e-mail, the date of
                receipt will be deemed the date on which such notice is
                transmitted.
            </p>
            <p>
                Streamlux’s failure to enforce any right or provision of these
                Terms will not be considered a waiver of those rights. Except as
                expressly set forth in these Terms, the exercise by either party
                of any of its remedies under these Terms will be without
                prejudice to its other remedies under these Terms or otherwise.
            </p>
            <p>
                <strong>21. Contact Information</strong>
            </p>
            <p>
                If you have any questions about these Terms or the Services
                please contact Streamlux at: support@streamlux.com
            </p>
        </div>
    );
};

export default TermsOfService;
